#print {
  padding: 0;

  #btnPrint {
    background: #225aa9;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  #printContent {
    position: relative;

    img.imgCertificado {
      height: 600px;
    }

    .logo {
      position: absolute;
      max-width: 130px;
      top: 30px;
      left: 40px;
    }

    > p {
      position: absolute;
      font-size: 1.2em;
      font-weight: bold;
    }

    .nome {
      top: 126px;
      left: 6px;
      width: 100%;
      text-align: center;
      color: #6fa082;
      font-size: 2em;
      font-weight: normal;
    }
    .cpf {
      top: 265px;
      left: 332px;
    }
    .certificacao {
      top: 206px;
      left: 8px;
      width: 100%;
      text-align: center;
      color: #fff;
      font-weight: normal;
      font-size: 1.6em;
    }

    .cargaHoraria {
      top: 240px;
      left: -34px;
      width: 100%;
      text-align: center;
      color: #fff;
      font-weight: normal;
      font-size: 1.6em;
    }

    .baseLegal {
      top: 387px;
      left: 280px;
      font-size: 1.1em;
      font-weight: normal;
    }

    .data {
      top: 242px;
      left: 548px;
      font-size: 1.4em;
      color: #fff;
      font-weight: normal;
    }

    .codigo {
      top: 396px;
      left: 373px;
      font-size: 1.1em;
      color: #fff;
      font-weight: normal;
    }
  }
}

@media print {
  @page {
    size: A4 landscape;
    margin: 0;
  }

  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  #btnPrint {
    display: none;
  }

  #print {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    page-break-before: auto;
    page-break-inside: avoid;
  }

  #printContent {
    position: relative;

    img.imgCertificado {
      max-width: 100%;
      max-height: 100%;
      height: 600px;
    }
  }
}

#modal {
  &.text {
    .modal-content {
      &.modal-content-print {
        max-width: 57rem;
      }
    }
  }
}
